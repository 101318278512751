/* eslint-disable @typescript-eslint/no-unused-vars */
import Tooltip from 'components/atoms/Tooltip/Tooltip'
import 'components/organisms/Table/_table.scss'
import { TABLE } from 'constants/common'
import useIsMobile from 'hooks/useIsMobile'
import { t } from 'i18next'
import { isEmpty, noop } from 'lodash'
import camelCase from 'lodash/camelCase'
import map from 'lodash/map'
import { useEffect, useState } from 'react'
import { IoMdArrowDropright } from 'react-icons/io'

interface TableConfig {
  loading?: boolean
  onSort?: (type: string) => void
  sortMethod?: string
  sortType?: string
  showSort?: boolean
  setOnMembers?: any
  tableWidth?: any
  onCreate?: () => void
  viewModal?: boolean
  config?: {
    headers?: { textColor?: string; bgColor?: string; textSize?: string; textCase?: string; textWeight?: string }
    dataList?: {
      textSize?: string
      link?: boolean
      items?: { [key: string]: any }[]
      withImage?: React.ReactNode
      onItemClick?: (...args: any[]) => void
      textColor?: string
      dropdown?: (item?: any) => void | React.ReactNode
      bgColor?: string
      pattern?: string
      selectedItem?: any
      patternConfig?: { firstColor?: string; secondColor?: string }
    }
  }
  headers: string[]
  dataList: { [key: string]: any }[]
  ancestor?: any
  excludedGoals?: any
  setExcludedGoals?: any
  onGoalsRatingChange?: any
  bonus?: any
  total?: { [key: string]: any }
  showTotal?: boolean
}

const Table2 = (props: TableConfig) => {
  const {
    dataList,
    headers,
    bonus,
    config,
    loading,
    tableWidth,
    onSort,
    sortMethod,
    sortType,
    showSort = false,
    onCreate,
    viewModal,
    ancestor,
    excludedGoals,
    setExcludedGoals,
    onGoalsRatingChange,
    total,
    showTotal,
  } = props

  const loadingList = [
    { id: '1', fullName: '', title: '', permissions: '' },
    { id: '2', fullName: '', title: '', permissions: '' },
  ]
  const {
    textColor: hTextColor,
    bgColor: hBgColor,
    textSize: hTextSize,
    textCase: hTextCase,
    textWeight: hTextWeight,
  } = config?.headers || {} // h = header
  const {
    textColor: bTextColor,
    textSize: bTextSize,
    pattern,
    patternConfig,
    dropdown,
    onItemClick = noop,
    selectedItem,
    withImage,
    link = false,
  } = config?.dataList || {} // b = body
  const colWidth = `${60 / (headers.length - 1)}%`

  const [divColor, setDivColor] = useState('')

  const [bonusRow, setBonusRow] = useState(null)

  useEffect(() => {
    if (bonus === 'employee bonus') {
      // Calculate sum of salaries and bonuses
      const salarySum = dataList.reduce((acc, curr) => {
        const salary = parseFloat(curr.salary)
        return isNaN(salary) ? acc : acc + salary
      }, 0)
      const bonusSum = dataList.reduce((acc, curr) => {
        const bonus = parseFloat(curr.bonus)
        return isNaN(bonus) ? acc : acc + bonus
      }, 0)

      // Find the indices of 'Salary' and 'Bonus' headers
      const salaryIndex = headers.indexOf(t(TABLE.EMPLOYEE_BONUS_HEADER.SALARY))
      const bonusIndex = headers.indexOf(t(TABLE.EMPLOYEE_BONUS_HEADER.BONUS))

      // Create the bonus row object
      const bonusRowData = {
        id: 'bonus-row', // Provide a unique ID for the bonus row
        // Create an array with dashes for all columns
        values: Array(headers.length).fill('-'),
      }

      // Place the 'Total' label at the Name column index
      bonusRowData.values[0] = <strong>Total</strong>

      // Place the salary sum at the salary index
      bonusRowData.values[salaryIndex] = salarySum.toString()

      // Place the bonus sum at the bonus index
      bonusRowData.values[bonusIndex] = bonusSum.toString()

      // Set the bonus row
      setBonusRow(bonusRowData)
    } else {
      // Reset bonus row if bonus prop is not 'employee bonus'
      setBonusRow(null)
    }
  }, [bonus, dataList, headers])

  const handleExclude = (id) => {
    setExcludedGoals((prevSelected) => {
      if (prevSelected.includes(id)) {
        setDivColor('transparent')
        return prevSelected.filter((selectedId) => selectedId !== id)
      } else {
        setDivColor('salmon')
        return [...prevSelected, id]
      }
    })
  }

  const isMobile = useIsMobile()

  const getDepartmentTooltip = (value) => {
    value
      ?.slice(0 - value?.length - 1)
      ?.map((dept) => dept?.name)
      .join(', ')
  }
  const shouldScrollHorizontally = headers.length > 7

  const headerCellWidth = shouldScrollHorizontally ? `${(100 / headers.length).toFixed(2)}%` : ''
  return (
    <div className="flex flex-col">
      <div
        style={{
          width: isMobile ? 'calc(100% - 1px)' : 'unset',
          overflowX: isMobile ? 'scroll' : shouldScrollHorizontally ? 'auto' : 'unset',
          zoom: isMobile ? '0.8' : '0',
        }}
        className="py-2 align-middle inline-block min-w-full"
      >
        <div className={`sm:rounded-lg`}>
          <table
            className={`${!isEmpty(tableWidth) ? tableWidth : 'min-w-full'} divide-y-0 divide-gray-700 font-roboto`}
          >
            <thead className={`${hBgColor} border-b `}>
              <tr className="m-0">
                {map(headers, (header, idx) => {
                  let item: any,
                    value = null
                  if (!loading) {
                    item = dataList[idx]
                    value = item?.[camelCase(header.toLowerCase())]
                  }

                  return (
                    <th
                      key={header}
                      scope="col"
                      style={
                        header === 'Title' || (header === 'Department' && !shouldScrollHorizontally)
                          ? { width: '50%' }
                          : shouldScrollHorizontally
                          ? { minWidth: headerCellWidth }
                          : { width: colWidth }
                      }
                      className={`${hTextColor || 'text-gray-500'} px-2 py-4 ${
                        header === 'dropdown' ? 'text-right pr-4' : isNaN(value) ? 'text-left' : 'text-center'
                      } ${hTextSize || 'text-base'} ${hTextCase || 'uppercase'} ${
                        hTextWeight || 'font-bold'
                      } tracking-wider ${shouldScrollHorizontally ? 'whitespace-nowrap pr-8' : ''}`}
                    >
                      <div
                        onClick={() => {
                          if (showSort) {
                            onSort(header)
                          }
                        }}
                        className={`${
                          isNaN(value) ? 'justify-start' : 'justify-center'
                        } flex items-center gap-2 cursor-pointer`}
                      >
                        {header === 'dropdown' ? '' : header}
                        {!(header === 'dropdown') && showSort && (
                          <IoMdArrowDropright
                            className={
                              sortMethod === 'ASC' && sortType === camelCase(header.toLowerCase())
                                ? 'rotate-90 transform'
                                : sortMethod === 'DESC' && sortType === camelCase(header.toLowerCase())
                                ? '-rotate-90 transform'
                                : 'rotate-90 transform'
                            }
                          />
                        )}
                      </div>
                    </th>
                  )
                })}
              </tr>
            </thead>
            {!viewModal && <></>}
            <tbody className="">
              {map(loading ? loadingList : dataList, (item, idx) =>
                loading ? (
                  <tr
                    role="button"
                    onClick={() => {
                      const item = config?.dataList?.items[idx]
                      onItemClick(item)
                    }}
                    className={` ${
                      bTextColor
                        ? bTextColor
                        : pattern === 'striped'
                        ? idx % 2 === 0
                          ? `${patternConfig?.firstColor}`
                          : `${patternConfig?.secondColor}`
                        : 'bg-transparent hover:bg-gray-100 cursor-pointer  border-b border-gray-200 '
                    }`}
                    key={idx}
                  >
                    {map(headers, (header, _idx) => {
                      return (
                        <td
                          key={item.id + '-' + header}
                          style={
                            header === 'Title'
                              ? { width: '50%' }
                              : shouldScrollHorizontally
                              ? { width: headerCellWidth }
                              : { width: colWidth }
                          }
                          className={`${bTextColor || 'text-gray-500'}  px-4 py-4  ${
                            header === 'dropdown' ? 'text-right pr-4' : 'text-left'
                          } whitespace-nowrap text-base`}
                        >
                          <div className="h-4 bg-gray-200 animate-pulse duration-700 rounded"></div>
                        </td>
                      )
                    })}
                  </tr>
                ) : (
                  <tr
                    role="button"
                    onClick={() => {
                      const item = config?.dataList?.items[idx]
                      onItemClick(item)
                    }}
                    className={` ${
                      bTextColor
                        ? bTextColor
                        : pattern === 'striped'
                        ? idx % 2 === 0
                          ? `${patternConfig?.firstColor}`
                          : `${patternConfig?.secondColor}`
                        : `${
                            selectedItem?.id === item?.id && 'bg-green-secondary hover:bg-green-secondary'
                          } font-roboto hover:bg-gray-100 cursor-pointer  border-b border-gray-200 `
                    }`}
                    key={idx}
                  >
                    {map(headers, (header, _idx) => {
                      const value = item[camelCase(header.toLowerCase())]
                      return (
                        <td
                          key={item.id + '-' + header}
                          style={
                            header === 'Title'
                              ? { width: '50%' }
                              : shouldScrollHorizontally
                              ? { width: headerCellWidth }
                              : { width: colWidth }
                          }
                          className={`${bTextSize || 'text-base'} ${bTextColor || 'text-black'}  px-2 py-2  ${
                            header === 'dropdown' ? 'text-right pr-4' : isNaN(value) ? 'text-left' : 'text-center'
                          } whitespace-nowrap ${excludedGoals?.includes(item.id) && 'line-through'}`}
                        >
                          {loading ? (
                            <div className="h-5 bg-gray-200 animate-pulse  rounded"></div>
                          ) : header === 'dropdown' ? (
                            dropdown(config?.dataList?.items[idx])
                          ) : (ancestor === 'analytics' || ancestor === 'admin-employees') &&
                            header === 'Department' ? (
                            <>
                              {value?.length >= 2 ? (
                                <div className="flex gap-2 items-center">
                                  <div>{value[0]?.name}</div>
                                  <Tooltip
                                    text={value
                                      ?.slice(1 - value?.length)
                                      ?.map((dept) => dept?.name)
                                      .join(', ')}
                                    position="top"
                                  >
                                    <span className="bg-blue-300 py-1 px-2 rounded-full text-xs">
                                      + {value?.length - 1} more
                                    </span>
                                  </Tooltip>
                                </div>
                              ) : (
                                value?.map((dept) => <>{dept?.name}</>)
                              )}
                            </>
                          ) : value === 0 ? (
                            0
                          ) : (
                            value ||
                            (ancestor === 'manager-appraisal' && (
                              <input
                                type="checkbox"
                                checked={excludedGoals?.includes(item.id)}
                                onChange={() => {
                                  handleExclude(item.id)
                                }}
                              />
                            ))
                          )}
                        </td>
                      )
                    })}
                  </tr>
                )
              )}
              {!loading && showTotal && (
                <tr>
                  <td style={{ width: '50%' }}>{total.name}</td>
                  <td
                    style={shouldScrollHorizontally ? { width: headerCellWidth } : { width: colWidth }}
                    className={`${bTextSize || 'text-base'} ${
                      bTextColor || 'text-black'
                    }  px-2 py-2  whitespace-nowrap text-center`}
                  >
                    {total.meetings}
                  </td>
                  <td
                    style={shouldScrollHorizontally ? { width: headerCellWidth } : { width: colWidth }}
                    className={`${bTextSize || 'text-base'} ${
                      bTextColor || 'text-black'
                    }  px-2 py-2  whitespace-nowrap text-center`}
                  >
                    {total.goals}
                  </td>
                  <td
                    style={shouldScrollHorizontally ? { width: headerCellWidth } : { width: colWidth }}
                    className={`${bTextSize || 'text-base'} ${
                      bTextColor || 'text-black'
                    }  px-2 py-2  whitespace-nowrap text-center`}
                  >
                    {total.feedback}
                  </td>
                  <td
                    style={shouldScrollHorizontally ? { width: headerCellWidth } : { width: colWidth }}
                    className={`${bTextSize || 'text-base'} ${
                      bTextColor || 'text-black'
                    }  px-2 py-2  whitespace-nowrap text-center`}
                  >
                    {total.assessments}
                  </td>
                  <td
                    style={shouldScrollHorizontally ? { width: headerCellWidth } : { width: colWidth }}
                    className={`${bTextSize || 'text-base'} ${
                      bTextColor || 'text-black'
                    }  px-2 py-2  whitespace-nowrap text-center`}
                  >
                    {total.tasks}
                  </td>
                  <td
                    style={shouldScrollHorizontally ? { width: headerCellWidth } : { width: colWidth }}
                    className={`${bTextSize || 'text-base'} ${
                      bTextColor || 'text-black'
                    }  px-2 py-2  whitespace-nowrap text-center`}
                  >
                    {total.department}
                  </td>
                  <td
                    style={shouldScrollHorizontally ? { width: headerCellWidth } : { width: colWidth }}
                    className={`${bTextSize || 'text-base'} ${
                      bTextColor || 'text-black'
                    }  px-2 py-2  whitespace-nowrap text-center`}
                  ></td>
                  <td
                    style={shouldScrollHorizontally ? { width: headerCellWidth } : { width: colWidth }}
                    className={`${bTextSize || 'text-base'} ${
                      bTextColor || 'text-black'
                    }  px-2 py-2  whitespace-nowrap text-center`}
                  >
                    {total.lastActivity}
                  </td>
                </tr>
              )}

              {bonusRow && (
                <tr key={bonusRow.id}>
                  {/* Map over bonus row values to render cells */}
                  {bonusRow.values.map((value, index) => (
                    <td
                      className={`${bTextSize || 'text-base'} ${
                        bTextColor || 'text-black'
                      }  px-2 py-2  text-center whitespace-nowrap }`}
                      key={index}
                    >
                      {value}
                    </td>
                  ))}
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
export default Table2
