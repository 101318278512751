import { useMemo } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'

// Hook
export function useRouter() {
  const params = useParams()
  const location = useLocation()
  const navigate = useNavigate()

  // Return our custom router object
  // Memoize so that a new object is only returned if something changes
  return useMemo(() => {
    // Helper method to parse the query string
    const searchParams: any = new URLSearchParams(location.search)
    const query = {}
    for (const [key, value] of searchParams.entries()) {
      query[key] = value
    }

    return {
      // For convenience, add push() and replace() at top level
      push: navigate,
      replace: (path, state) => navigate(path, { state, replace: true }),
      pathname: location.pathname,

      // Merge params and parsed query string into single "query" object
      // so that they can be used interchangeably.
      // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
      query: {
        ...params,
        ...query,
      },

      // Include location and navigate objects so we have
      // access to extra React Router functionality if needed.
      location,
      navigate,
    }
  }, [params, location, navigate])
}
